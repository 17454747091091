@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-VariableFont_wght.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Italic-VariableFont_wght.woff2') format('woff2');
  font-weight: 100 900;
  font-style: italic;
  font-display: fallback;
}