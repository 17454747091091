@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

body {
    margin: 0;
    font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Remove unnecessary @import statements if fonts are locally hosted */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Plus+Jakarta+Sans:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

/* Additional custom styles */
.location-bar::-webkit-scrollbar {
    @apply w-1 h-1;
}

.location-bar::-webkit-scrollbar-track {
    @apply bg-green-900;
}

.location-bar::-webkit-scrollbar-thumb {
    @apply bg-green-300;
}

/* Custom layout */
.custom-color-picker .react-colorful {
    padding: 16px;
    @apply w-full h-full bg-gray-50 border-gray-300 rounded-lg border;
}

.custom-color-picker .react-colorful__saturation {
    margin: 15px 0;
    border-radius: 5px;
    border-bottom: none;
}

.custom-color-picker .react-colorful__hue {
    order: -1;
}

.custom-color-picker .react-colorful__hue,
.custom-color-picker .react-colorful__alpha {
    height: 14px;
    border-radius: 5px;
}

.custom-color-picker .react-colorful__hue-pointer,
.custom-color-picker .react-colorful__alpha-pointer {
    width: 20px;
    height: 20px;
}

.ekc-text-gradient {
    @apply bg-gradient-to-r from-[#337DB1] !to-[#6DB557] bg-clip-text inline-block text-transparent;
}

.ekc-image-gradient {
    @apply bg-gradient-to-r from-[#337DB1] !to-[#6DB557];
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 221.2 83.2% 53.3%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 221.2 83.2% 53.3%;
        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 217.2 91.2% 59.8%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;
        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 224.3 76.3% 48%;
    }
}
